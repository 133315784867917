<template lang='pug'>
  #app
    header-menu

    .page-container
      .page-content-wrapper
        router-view

        .container.container-fixed-lg.footer
          .copyright.sm-text-center
            p.small.no-margin.pull-left.sm-pull-reset
              span.hint-text Copyright © 2018
              span.font-montserrat &nbsp;annoyingdev&nbsp;
              | . &nbsp;
              span.hint-text All rights reserved.
              span.sm-block
                router-link(to='/privacy' class='m-l-10 m-r-10') Privacy Policy
                |
                router-link(to='/imprint' class='m-l-10') Imprint
            .clearfix

    .overlay.hide(data-pages='search')
      .overlay-content.has-results.m-t-20
        .container-fluid
          <!--img.overlay-brand(src='assets/img/logo.png', alt='logo', data-src='assets/img/logo.png', data-src-retina='assets/img/logo_2x.png', width='78', height='22')-->
          a.close-icon-light.overlay-close.text-black.fs-16(href='#')
            i.pg-close
        .container-fluid
          input#overlay-search.no-border.overlay-search.bg-transparent(placeholder='Search...', autocomplete='off', spellcheck='false')
          br
          .inline-block
            .checkbox.right
              input#checkboxn(type='checkbox', value='1', checked='checked')
              label(for='checkboxn')
                i.fa.fa-search
                |  Search within page
          .inline-block.m-l-10
            p.fs-13 Press enter to search
        | -->
        .container-fluid
          span
            strong suggestions :
          span#overlay-suggestions
          br
          .search-results.m-t-40
            p.bold Pages Search Results
            .row
              .col-md-6
                div
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      span.semi-bold.result-name ice cream
                      |  on pages
                    p.hint-text via john smith
                div
                  .thumbnail-wrapper.d48.circular.bg-success.text-white.inline.m-t-10
                    div T
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      span.semi-bold.result-name ice cream
                      |  related topics
                    p.hint-text via pages
                div
                  .thumbnail-wrapper.d48.circular.bg-success.text-white.inline.m-t-10
                    div
                      i.fa.fa-headphones.large-text
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      span.semi-bold.result-name ice cream
                      |  music
                    p.hint-text via pagesmix
              .col-md-6
                div
                  .thumbnail-wrapper.d48.circular.bg-info.text-white.inline.m-t-10
                    div
                      i.fa.fa-facebook.large-text
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      span.semi-bold.result-name ice cream
                      |  on facebook
                    p.hint-text via facebook
                div
                  .thumbnail-wrapper.d48.circular.bg-complete.text-white.inline.m-t-10
                    div
                      i.fa.fa-twitter.large-text
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      | Tweats on
                      span.semi-bold.result-name  ice cream
                    p.hint-text via twitter
                div
                  .thumbnail-wrapper.d48.circular.text-white.bg-danger.inline.m-t-10
                    div
                      i.fa.fa-google-plus.large-text
                  .p-l-10.inline.p-t-5
                    h5.m-b-5
                      | Circles on
                      span.semi-bold.result-name  ice cream
                    p.hint-text via google plus

</template>

<style lang="scss">
  @import "assets/plugins/font-awesome/css/font-awesome.css";
  @import "assets/plugins/pace/pace-theme-flash.css";
  @import "assets/plugins/bootstrap/css/bootstrap.css";
  @import "assets/plugins/jquery-scrollbar/jquery.scrollbar.css";
  @import "assets/plugins/select2/css/select2.min.css";
  @import "assets/css/pages-icons.css";
  @import "assets/scss/pages";

  #app {
    height: 100%;
  }

  .page-container {
    height: calc(100% - 75px);

    @media (min-width: 992px) {
      height: calc(100% - 125px);
    }
  }

  .card-maximize {
    cursor: pointer;
  }
</style>
<script>
  import HeaderMenu from './components/HeaderMenu'

  export default {
    components: {HeaderMenu},
    mounted () {
      const self = this
      this.$on('pgNotification', function (event) {
        self.notify(event.message, event.type)
      })
    },
    methods: {
      notify(message, type) {
        window.$('.page-content-wrapper').pgNotification({
            style: 'flip',
            message: message,
            position: 'top-right',
            timeout: 4000,
            type: type
        }).show()
      }
    }
  }
</script>
