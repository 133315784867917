<template lang='pug'>
.header
  .container
    <!--.header-inner.header-md-height-->
      <!--div-->
        <!--// START NOTIFICATION LIST-->
        <!--ul.d-lg-inline-block.d-none.notification-list.no-margin.b-grey.b-l.b-r.no-style.p-l-0.p-r-20-->
          <!--li.p-r-10.inline-->
            <!--a.header-icon.pg.pg-extra(href='#')-->
        <!--// END NOTIFICATIONS LIST-->
        <!--&lt;!&ndash;a.search-link.d-lg-inline-block.d-none(href='#', data-toggle='search')&ndash;&gt;-->
          <!--i.pg-search-->
          <!--| Type anywhere to&nbsp;-->
          <!--span.bold search-->

    .header-inner.justify-content-start.header-lg-height.title-bar
      .brand.inline.align-self-end
        a.help-icon.pg.pg-extra(href="javascript:;")
        <!--img(src='https://placekitten.com/17/20', alt='logo', data-src='assets/img/logo_s.png', data-src-retina='assets/img/logo_s_2x.png', width='17', height='20')-->
      h2.page-title.align-self-end {{ $route.meta.title }}
    .menu-bar.header-sm-height(data-pages-init='horizontal-menu', data-hide-extra-li='4')
      a.btn-link.toggle-sidebar.d-lg-none.pg.pg-close(href='#', data-toggle='horizontal-menu')
      ul
        li(:class='{"active": $route.path === "/"}' v-for='item in toolsMenuItems')
          router-link(:to='item.route' v-if='item.route')
            span.title {{ item.title }}

          a(href='javascript:;' v-if='item.items')
            span.title {{ item.title }}
            span.arrow
          ul(v-if='item.items')
            li(:class='{"active": $route.path === subItem.route}' v-for='subItem in item.items')
              router-link(:to='subItem.route')
                span.title {{ subItem.title }}


        <!--li(:class='{"active": $route.path === "/"}')-->
          <!--router-link(to='/') Home-->
        <!--li-->
          <!--router-link(to='/') Learn Python-->
        <!--li-->
          <!--a(href='javascript:;')-->
            <!--span.title Tools-->
            <!--span.arrow-->
          <!--ul-->
            <!--li(v-for='item in toolsMenuItems')-->
              <!--router-link(:to='item.route') {{ item.title }}-->


      a.search-link.d-flex.justify-content-between.align-items-center.d-lg-none(href='#', data-toggle='search')
        | Tap here to search
        i.pg-search.float-right

</template>

<script>
  import toolsMenuItems from '../assets/menus'

  export default {
    name: 'HeaderMenu',
    data () {
      return {
        toolsMenuItems: toolsMenuItems
      }
    }
  }
</script>

<style scoped>
  .help-icon {
    font-size: 24px;
  }
</style>
