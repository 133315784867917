import Vue from 'vue'
import Router from 'vue-router'
// import RegEx from './views/RegEx'
// import Base64 from './views/Base64'
// import CaseConverter from './views/CaseConverter'
// import JWT from './views/JWT'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      // component: Home,
      component: () => import(/* webpackChunkName: "ho" */ './views/Home.vue'),
      meta: {
        'some': 'thing',
        'subTitle': '',
        'title': 'Home',
        'description': '',
        'howtoUrl': 'https://placehold.it/300x100'
      }
    },
    {
      path: '/password-generator',
      name: 'password-generator',
      component: () => import(/* webpackChunkName: "pw" */ './views/PasswordGenerator'),
      meta: {
        'title': 'Password Generator',
        'subTitle': 'Getting Startet',
        'description': 'Generate strong and random Passwords',
        howto: 'Generate your Password (WLAN, Server etc.) by adjusting the settings.',
        'howtoUrl': '/header/password-generator.gif'
      }
    },
    {
      path: '/save-code-with-highlighting-on-evernote',
      name: 'save-code-with-highlighting-on-evernote',
      component: () => import(/* webpackChunkName: "evercode" */ './views/EverCode'),
      meta: {
        'title': 'Evernote Code',
        'subTitle': 'Getting Startet',
        'description': 'Save your Code on your Evernote',
        howto: 'Paste your Code into the editor and click download.<br/>Import the Evernote-File to Evernote',
        'howtoUrl': '/header/save-evernote.gif'
      }
    },
    {
      path: '/json-validator-formatter',
      name: 'json',
      // component: JSONView,
      component: () => import(/* webpackChunkName: "js" */ './views/JSONView'),
      meta: {
        'title': 'JSON Format, Validator & Path',
        'subTitle': 'Getting Startet',
        'description': 'Filter, validate, transform JSON objects and download the filtered result',
        'howto': 'Paste your JSON into the INPUT-Field, apply json-path by pressing Enter and download the result if needed.',
        'howtoUrl': '/header/json-path.gif'
      }
    },
    {
      path: '/json-to-yaml-2-json',
      name: 'json-yaml-json',
      // component: JSONView,
      component: () => import(/* webpackChunkName: "jsonyaml" */ './views/JSONYaml'),
      meta: {
        'title': 'JSON 2 Yaml 2 JSON',
        'subTitle': 'Getting Startet',
        'description': 'Convert JSON 2 Yaml and Yaml 2 JSON',
        'howto': 'Paste your JSON into the INPUT-Field, apply json-path by pressing Enter and download the result if needed.',
        'howtoUrl': '/header/json-path.gif'
      }
    },
    {
      path: '/remove-duplicate-link',
      name: 'remove-duplicate-link',
      component: () => import(/* webpackChunkName: "link-unique" */ './views/LinkUniqueifier'),
      meta: {
        'title': '5 Legal Instagram Engagement Group Steps - That saves you up to 70% of your time\n',
        'subTitle': 'Getting Startet',
        'description': 'Convert JSON 2 Yaml and Yaml 2 JSON',
        'howto': 'Paste your JSON into the INPUT-Field, apply json-path by pressing Enter and download the result if needed.',
        'howtoUrl': '/header/json-path.gif'
      }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: () => import(/* webpackChunkName: "terms" */ './views/Imprint'),
      meta: {
        'title': 'Imprint',
        'description': ''
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "terms" */ './views/Privacy'),
      meta: {
        'title': 'Privacy policy',
        'description': ''
      }
    },
    {
      path: '/learn-python/:course',
      name: 'python',
      component: () => import(/* webpackChunkName: "python" */ './views/Python'),
      meta: {
        'title': 'Learn Python - The interactive way',
        'description': 'Learn Python'
      }
    }

    //
    // {
    //   path: '/regex-tester-validator',
    //   name: 'regex',
    //   component: RegEx,
    //   meta: {
    //     'title': 'RegEx tester & validator',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },

    // {
    //   path: '/base64-encode-decode',
    //   name: 'base64',
    //   component: Base64,
    //   meta: {
    //     'title': 'Base64 encoder & decoder',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/case-converter',
    //   name: 'case-converter',
    //   component: CaseConverter,
    //   meta: {
    //     'title': 'Case converter',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/json-web-token-jwt',
    //   name: 'jwt',
    //   component: JWT,
    //   meta: {
    //     'title': 'JWT',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/md5-sha-converter',
    //   name: 'md5',
    //   component: () => import(/* webpackChunkName: "md" */ './views/MD5SHA'),
    //   meta: {
    //     'title': 'MD5 & SHA converter',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/word-character-count',
    //   name: 'word-count',
    //   component: () => import(/* webpackChunkName: "pw" */ './views/WordCount'),
    //   meta: {
    //     'title': 'Word count',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/pdf',
    //   name: 'pdf',
    //   component: () => import(/* webpackChunkName: "pdf" */ './views/PDF'),
    //   meta: {
    //     'title': 'PDF editing',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/csv-excel-converter',
    //   name: 'csv-excel',
    //   component: () => import(/* webpackChunkName: "csv" */ './views/CSVExcel'),
    //   meta: {
    //     'title': 'CSV Excel converter',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/extract-text-from-image-ocr',
    //   name: 'ocr',
    //   component: () => import(/* webpackChunkName: "ocr" */ './views/OCR'),
    //   meta: {
    //     'title': 'OCR - Extract text from image',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/time-calculation-convert',
    //   name: 'time-calc',
    //   component: () => import(/* webpackChunkName: "time" */ './views/TimeCalculator'),
    //   meta: {
    //     'title': 'Time calculator & converter',
    //     'subTitle': 'Getting Startet',
    //     'description': '',
    //     'howtoUrl': 'https://placehold.it/300x100'
    //   }
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ]
})
