import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import VueAnalytics from 'vue-analytics'
// import pace from 'pace'

if (process.env.NODE_ENV !== 'development') {
  Vue.use(
    VueAnalytics,
    {
      id: 'UA-127406445-1',
      router: router,
      autoTracking: {
        pageviewTemplate(route) {
          return {
            page: route.path,
            title: route.meta.title,
            location: window.location.href
          }
        }
      }
    }
  )
}


Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
  mounted: () => {
    window.console.log('DONE')
    setTimeout(function () {
      document.dispatchEvent(new Event('x-app-rendered'))
    }, 2000)

  }
}).$mount('#app')
